import React from "react";
import { Link } from "gatsby";
import trackLinkClick from "../analytics";

interface BaseProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  eventName: string;
  linkType: "page_link" | "outbound_link" | "event_link";
  className?: string;
  href?: string;
  details?: object;
}

export default function MyLink({
  href,
  eventName,
  linkType,
  className,
  onClick,
  children,
  details,
  ...rest
}: BaseProps) {
  if (linkType == "page_link" && href) {
    return (
      <Link
        to={href}
        className={className}
        onClick={(event) => {
          trackLinkClick(eventName, "internal", href, details);
          onClick ? onClick(event) : undefined;
        }}
      >
        {children}
      </Link>
    );
  } else if (linkType == "outbound_link") {
    return (
      <a
        href={href}
        target="_blank"
        className={className}
        onClick={(event) => {
          trackLinkClick(eventName, "outbound", href, details);
          onClick ? onClick(event) : undefined;
        }}
        {...rest}
      >
        {children}
      </a>
    );
  }
  return (
    <a
      href={href}
      className={className}
      onClick={(event) => {
        trackLinkClick(eventName, "internal", href, details);
        onClick ? onClick(event) : undefined;
      }}
      {...rest}
    >
      {children}
    </a>
  );
}
